// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
// import TransparentBlogCard from "customBuilds/Cards/TransparentBlogCard";
import UserCompanyDataCard from "customBuilds/Cards/UserCompanyData";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/intraas/mba.png";
import post2 from "assets/images/intraas/estater.png";
import post3 from "assets/images/intraas/fs.png";
// import post4 from "assets/images/examples/blog2.jpg";

function UserCompanyData() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
        >
          <MKTypography variant="h3" mb={6} fontWeight="bold">
            TRUSTED AND RECOMMENDED BY
          </MKTypography>
        </Grid>
        <Grid
          container
          spacing={3}
          flexDirection="row"
          alignContent="center"
          alingItem="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
        >
          <Grid item alignItems="center" xs={12} sm={6} lg={4} mt={12}>
            <UserCompanyDataCard
              image={post1}
              // title="MBA RENEZVOUS"
              // description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with ..."
              action={{}}
            />
          </Grid>
          <Grid item alignItems="center" xs={12} sm={6} lg={3}>
            <UserCompanyDataCard
              image={post2}
              // title="ESTATER"
              // description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with ..."
              action={{}}
            />
          </Grid>
          <Grid item alignItems="center" xs={12} sm={6} lg={4} mt={12}>
            <UserCompanyDataCard
              image={post3}
              // title="FUTURE STANDARD"
              // description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with ..."
              action={{}}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Flexible work hours"
              description="Rather than worrying about switching offices every couple years, you stay in the same place."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                label: "read more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UserCompanyData;
