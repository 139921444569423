// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "customBuilds/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
// import post1 from "assets/images/examples/testimonial-6-2.jpg";
import mfa1 from "assets/images/intraas/mfa1.jpg";
import nologin from "assets/images/intraas/nologin.jpg";
import rdp from "assets/images/intraas/rdp.png";
import ssh from "assets/images/intraas/ssh.png";
import vnc from "assets/images/intraas/vnc.png";
import network from "assets/images/intraas/network.png";
import cloudonprem from "assets/images/intraas/cloudonprem.png";
import webappstime from "assets/images/intraas/webappstime.png";
// import sso4 from "assets/images/intraas/personcoding.jpg";
// import post2 from "assets/images/examples/testimonial-6-3.jpg";
// import post3 from "assets/images/examples/blog-9-4.jpg";
// import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
        >
          <MKTypography variant="h1" mb={6}>
            Infrastructure Access redefined
          </MKTypography>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard image={mfa1} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h3" fontWeight="light" color="medium" textGradient>
              Intraas Identity-based Remote Access allows access of on-premise or on-cloud machine
              access with Single Identity without setting up expensive infrastructure of VPN &
              leaselines. Fine-grained control policies helps IT Administrator to manage access
              easily & effectively. Remote Access of machine supports various access protocols like
              RDP, VNC, SSH etc.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Access of Windows Machine on RDP
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Administrator can create machine access with easy & intuitive UI of intraas and grant
              access to users as and when required. Window machine must have desired RDP licenses to
              allow single or multiple users access to machine. RDP session can be opened in Browser
              on Desktop/Laptop/Phone/Tablets, making user to access Desktop of remote machine from
              anywhere.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={rdp} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard image={ssh} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Access of Unix/Linux Machine on SSH
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Administrator can create machine access with easy & intuitive UI of intraas and grant
              access to users as and when required. Administrator can create or used existing SSH
              credentials while provisioning connections configuration. Created connections can be
              shared with multiple users, without sharing the actual machine credentials with user
              and let user to access machine by their own identity provided by Intraas Identity &
              Access Management platform.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Access of Unix/Linux/Windows Machine on VNC
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Administrator can create machine access with easy & intuitive UI of intraas and grant
              access to users as and when required. Once VNC Server is setup on the machine,
              Administrator can use existing VNC credentials while provisioning connections
              configuration. Created connections can be shared with multiple users, without sharing
              the actual VNC session credentials with user and let user to access machine by their
              own identity provided by Intraas Identity & Access Management platform.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={vnc} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={network} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Virtual Sites Management & Network Management
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Intraas UI let Administrator create virtual sites, for example one site for New York
              and one site for New Delhi. Each site can have networks. Each networks can contain
              virtual machines. Each virtual machine can be mapped to real machine by Intraas
              Software Agent connecting to defined underlying virtual network. Sites & Network
              Isolation of resources provide better control over machines and web apps access, given
              to user. For example, people sitting in New York office(Virtual office) can be access
              to resource belonging to New York site only.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Secure & Bring On-premise/on-cloud hosted Web Apps online with access from anywhere
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              With Intraas Advanced Identity-based Infrastructure, Administrator can secure and
              bring webapps online ,whether they are hosted on on-premise machine or on-cloud
              machine. Each Web app is assigned unique Domain Name URI( URI having sub-domain of
              organization selected domain name). Access to URI is controlled and protected by
              Intraas IAM platform.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={cloudonprem} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={webappstime} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Time based access of Web Apps
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Administrator can define the time ( schedule or days/hours etc) till when access of
              Web Apps will remain online with its allocated URI. One time is expired, URI is no
              more accessible.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Temporary Non-Login based access of Web Apps on Internet
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Team sometimes required to share demo of their products or apps. In such case, they
              want apps to remain accessible for short-time , without prompting for
              username/password. In such, intraas allows to provision the Web Apps to bring them
              online with a unique URI, which does not ask for any authentication with pre-defined
              life. When life comes to end, Web App can not be access from the issued URI.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={nologin} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
      </Container>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          width="100%"
          borderRadius="xl"
          shadow="xl"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6, mt: 12 }}
        >
          <MKTypography variant="h1" mb={6}>
            Let&#39;s Start Today
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
