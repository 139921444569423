// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/intraas/email.jpg";
import React, { useState } from "react";
import sendEmail from "../api/ApiCall";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  // eslint-disable-next-line no-return-await
  const sendEmailResponse = async (data) => await sendEmail(data);

  const handleFormData = (event) => {
    // console.log(event);
    event.preventDefault();
    setIsDisabled(true);
    const data = {
      from: "Intraas",
      username: name,
      companyName: company,
      companyEmail: email,
    };
    const apiResponse = sendEmailResponse(data);
    apiResponse.then((result) => {
      setShowMessage(true);
      if (result.data === "250") {
        setStatusCode("250");
        setMessage("Thanks for Contacting Us. Our team will contact you back.");
      } else {
        setStatusCode("100");
        setMessage("Server is busy. We are not able to accept you email.Please try again");
      }
      setTimeout(() => {
        setShowMessage(false);
        setStatusCode("");
        setMessage("");
        setIsDisabled(false);
      }, 4000);
    });
  };
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h2" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography variant="body1" color="white" opacity={0.8} mb={3}>
                      Fill up the form and our Team will get back to you within 24 hours.
                    </MKTypography>
                    {/* <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+40) 772 100 200
                      </MKTypography>
                    </MKBox> */}
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        // opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        sales@snetlabs.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        // opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        91springboard,Mohan Estate, New Delhi, 110044
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      {/* <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton> */}
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        href="https://www.linkedin.com/company/intraas-snetlabs/"
                        type="external"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox
                  width="100%"
                  component="form"
                  onSubmit={handleFormData}
                  method="post"
                  autoComplete="off"
                >
                  {showMessage ? (
                    <>
                      {statusCode === "250" ? (
                        <MKTypography
                          textAlign="center"
                          my={2}
                          max={0}
                          style={{ color: "green", fontSize: "14px" }}
                        >
                          {message}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          textAlign="center"
                          variant="body"
                          mb={2}
                          mx={0}
                          style={{ color: "red" }}
                        >
                          {message}
                        </MKTypography>
                      )}
                    </>
                  ) : null}
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h3" mb={1}>
                      Let&#39;s Collaborate
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={2}>
                        <MKInput
                          variant="standard"
                          label="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={2}>
                        <MKInput
                          variant="standard"
                          label="Company Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            setCompany(e.target.value);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={2}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton
                        type="submit"
                        variant="gradient"
                        disabled={isDisabled}
                        color="secondary"
                      >
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
