/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import "./style.css";
import ListItemText from "@mui/material/ListItemText";
// import Divider from "@mui/material/Divider";
// Images
import nonetwork from "assets/images/intraas/nonetwork.png";
import internal from "assets/images/intraas/internal2.png";
import difficult from "assets/images/intraas/insecureremovebgpreview1.png";
import insecuremachineremoteaccess from "assets/images/intraas/insecuremachineremoteaccess.png";
import unsecureinternalwebapp from "assets/images/intraas/unsecureinternalwebapp.png";
import sshvncrdp from "assets/images/intraas/sshvncrdp.png";
import noschedulewebapps from "assets/images/intraas/noschedulewebapps1.png";
// import nonetwork from "assets/images/intraas/nonetwork.png";

// import dataConversion from "assets/images/gif/data-conversion.gif";
// import annotation from "assets/images/gif/annotation.gif";
import { useState } from "react";
// import "../../../../assets/style.css";

function Newsletter() {
  const [selectedIndex, setSelectedIndex] = useState(3);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const showImage = (event, index) => {
    setSelectedIndex(index);
  };
  const hideImage = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <MKBox
      component="section"
      py={2}
      mb={4}
      style={
        {
          // backgroundImage: "linear-gradient(90deg, rgb(151, 210, 249), rgb(235 237 201))",
        }
      }
    >
      <Container>
        <Grid container alignItems="center" my={6}>
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <Grid
              container
              item
              xs={12}
              lg={10}
              flexDirection="column"
              alignItems="left"
              sx={{ textAlign: "left", my: 0 }}
            >
              <MKTypography variant="h1" mb={6} fontWeight="bold" alignItems="left">
                IT Challenges
              </MKTypography>
            </Grid>
            <MKTypography variant="body2" color="black" mb={3}>
              <List>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                  onMouseEnter={(event) => showImage(event, 0)}
                  onMouseLeave={(event) => hideImage(event, 0)}
                  sx={{ background_color: "black" }}
                >
                  <ListItemText primary="No Network isolation." />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  onMouseEnter={(event) => showImage(event, 1)}
                  onMouseLeave={(event) => hideImage(event, 1)}
                >
                  <ListItemText primary="Internal Web Apps access from Internet." />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                  onMouseEnter={(event) => showImage(event, 2)}
                  onMouseLeave={(event) => hideImage(event, 2)}
                >
                  <ListItemText primary="Difficult User Access Management" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                  onMouseEnter={(event) => showImage(event, 3)}
                  onMouseLeave={(event) => hideImage(event, 3)}
                >
                  <ListItemText primary="In-Secure Machine Remote Access" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 4}
                  onClick={(event) => handleListItemClick(event, 4)}
                  onMouseEnter={(event) => showImage(event, 4)}
                  onMouseLeave={(event) => hideImage(event, 4)}
                >
                  <ListItemText primary="Un-Secured Internal Web Apps" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  onMouseEnter={(event) => showImage(event, 5)}
                  onMouseLeave={(event) => hideImage(event, 5)}
                >
                  <ListItemText primary="No Scheduled Access to Remote Machine" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  onMouseEnter={(event) => showImage(event, 6)}
                  onMouseLeave={(event) => hideImage(event, 6)}
                >
                  <ListItemText primary="No Scheduled Access to Web Apps." />
                </ListItemButton>
              </List>
            </MKTypography>
            {/* <Grid container spacing={1}>
              <Grid item xs={8}>
                <MKInput type="email" label="Email Here..." fullWidth />
              </Grid>
              <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  Subscribe
                </MKButton>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }} className="card-image">
            <MKBox position="relative">
              {selectedIndex === 0 ? (
                <MKBox
                  className="card-image"
                  component="img"
                  src={nonetwork}
                  alt="macbook"
                  width="100%"
                />
              ) : (
                <>
                  {selectedIndex === 1 ? (
                    <>
                      <MKBox
                        className="card-image"
                        component="img"
                        src={internal}
                        alt="macbook"
                        width="100%"
                      />
                    </>
                  ) : (
                    <>
                      {selectedIndex === 2 ? (
                        <>
                          <MKBox
                            className="card-image"
                            component="img"
                            src={difficult}
                            alt="macbook"
                            width="100%"
                          />
                        </>
                      ) : (
                        <>
                          {selectedIndex === 3 ? (
                            <>
                              <MKBox
                                className="card-image"
                                component="img"
                                src={insecuremachineremoteaccess}
                                alt="macbook"
                                width="100%"
                              />
                            </>
                          ) : (
                            <>
                              {selectedIndex === 4 ? (
                                <>
                                  <MKBox
                                    className="card-image"
                                    component="img"
                                    src={unsecureinternalwebapp}
                                    alt="macbook"
                                    width="100%"
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedIndex === 5 ? (
                                    <>
                                      <MKBox
                                        className="card-image"
                                        component="img"
                                        src={sshvncrdp}
                                        alt="macbook"
                                        width="100%"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {selectedIndex === 6 ? (
                                        <>
                                          <MKBox
                                            className="card-image"
                                            component="img"
                                            src={noschedulewebapps}
                                            alt="macbook"
                                            width="100%"
                                          />
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
