// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "customBuilds/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
// import post1 from "assets/images/examples/testimonial-6-2.jpg";
import sso from "assets/images/intraas/sso2.jpg";
import sso2 from "assets/images/intraas/man2.jpg";
import sso3 from "assets/images/intraas/pairprograming.jpg";
import sso4 from "assets/images/intraas/staging.jpg";
import sso5 from "assets/images/intraas/domain.png";
import sso6 from "assets/images/intraas/sociallogin.png";
import sso7 from "assets/images/intraas/Socialtree.png";
import sso8 from "assets/images/intraas/multiplelogin.png";
import sso9 from "assets/images/intraas/cio.jpg";
// import post2 from "assets/images/examples/testimonial-6-3.jpg";
// import post3 from "assets/images/examples/blog-9-4.jpg";
// import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
        >
          <MKTypography variant="h1" mb={6}>
            Cake walk with Single Sign On
          </MKTypography>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard image={sso} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Yes you heard right!! With enhanced security, Single Sign is now easiest to configure
              , supported by your own Dedicated intraas CIO. Now you can onboard your employees,
              customers & partners providing access to your Apps & infrastructure in most intuitive
              and easiest way.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Unified Simple & Secure
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Intraas identity and access management(IAM) platform provides ease of use with
              enhanced security and greater control. Platform is well suited for all size
              enterprises as well as for their SaaS Customers/Partners. When you are Small Your
              growing organization demands it. Single Sign On (SSO) allows users to login once while
              not only giving access to Apps but also to infrastructure, no matter it is placed on
              premise or On Cloud. Onboarding & offboarding of user can not only be done by yourself
              easily but can also be delegated to your own intraas Dedicated CIO.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <TransparentBlogCard image={sso2} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso3} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              No-Code Integration of Apps with SSO
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              With Intraas Identity & Access Management platform, you can deploy or protects
              existing applications with SSO without writing even a single line of code.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Support of Seperate SSO for Staging & Production environments
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              While single intraas SSO identity system can be accessing multiple environment,
              intraas enables organization to have different SSO identity system as well for each
              environment, be it production or staging.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso4} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard image={sso5} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Custom Domain Names Access for each SSO environment
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              You can provision separate domain names for each environment like staging and
              production to access them with totally separated SSO identity system. This is
              equivalent to have multiple child organizations in a parent organization.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Social Login Support
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Intraas SSO allows administrators to integrate their social login, like Google,
              Facebook, Github etc without re-creating users account.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso6} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso9} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Delegated Administration
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              With Intraas Dedicated CIO, you can delegate administrative tasks like on-boarding &
              off-boarding of users, provide/revoke access to/from user for Apps and infrastructure(
              on-premise or on-cloud).
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Social Apps Integration
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              Your can integrate any number of Apps supporting SAML or OpenId connect, like GitHub,
              Gitlab, Linked, Zoom, Jira, Trello, Dropbox etc.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso7} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={sso8} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Shared Logins to Machines/Servers
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              You can allow access to multiple users to a machine/server with shared credentials
              without revealing to user. For example, SSH credentials to AWS server need not to be
              shared with each user, while authorized intraas user can access AWS server with his
              own SSO login.For extra security, you can not only track the user activities but also
              record their activities performed on server on demand.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
      </Container>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          width="100%"
          borderRadius="xl"
          shadow="xl"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6, mt: 12 }}
        >
          <MKTypography variant="h1" mb={6}>
            Let&#39;s Start Today
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
