import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import React, { useState } from "react";
// Material Kit 2 React examples
import DefaultNavbar from "customBuilds/Navbars/DefaultNavbar";
import DefaultFooter from "customBuilds/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/intraas/contactUs4.png";
import sendEmail from "../api/ApiCall";
// Image

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  // eslint-disable-next-line no-return-await
  const sendEmailResponse = async (data) => await sendEmail(data);

  const handleFormData = (event) => {
    // console.log(event);
    event.preventDefault();
    setIsDisabled(true);
    const data = {
      from: "Intraas",
      username: name,
      companyName: company,
      companyEmail: email,
    };
    const apiResponse = sendEmailResponse(data);
    apiResponse.then((result) => {
      setShowMessage(true);
      if (result.data === "250") {
        setStatusCode("250");
        setMessage("Thanks for Contacting Us. Our team will contact you back.");
      } else {
        setStatusCode("100");
        setMessage("Server is busy. We are not able to accept you email.Please try again");
      }
      setTimeout(() => {
        setShowMessage(false);
        setStatusCode("");
        setMessage("");
        setIsDisabled(false);
      }, 4000);
    });
  };
  return (
    <>
      <DefaultNavbar routes={routes} />

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          {/* <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="100%"
            height="50vh"
            borderRadius="lg"
            ml={2}
            mt={9}
            background-size="cover"
            sx={{ backgroundImage: `url(${bgImage})` }}
          /> */}
          <img
            src={bgImage}
            alt="test"
            style={{ marginTop: "80px", marginLeft: "20px", borderRadius: "50px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Request a free demo
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                onSubmit={handleFormData}
                method="post"
                autoComplete="off"
              >
                {showMessage ? (
                  <>
                    {statusCode === "250" ? (
                      <MKTypography
                        textAlign="center"
                        my={2}
                        max={0}
                        style={{ color: "green", fontSize: "12px" }}
                      >
                        {message}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        variant="body1"
                        textAlign="center"
                        mb={2}
                        mx={0}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {message}
                      </MKTypography>
                    )}
                  </>
                ) : null}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Company Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="What can we help you?"
                      placeholder="Describe your problem in at least 250 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid> */}
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    disabled={isDisabled}
                    color="secondary"
                  >
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
