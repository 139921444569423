// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "customBuilds/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
// import post1 from "assets/images/examples/testimonial-6-2.jpg";
import mfa1 from "assets/images/intraas/mfa1.jpg";
import mfa2 from "assets/images/intraas/mfa2.png";
import mfa3 from "assets/images/intraas/mfa3.png";
// import sso4 from "assets/images/intraas/personcoding.jpg";
// import post2 from "assets/images/examples/testimonial-6-3.jpg";
// import post3 from "assets/images/examples/blog-9-4.jpg";
// import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
        >
          <MKTypography variant="h1" mb={6}>
            Unbeatable Security with our Multi-Factor Authentication
          </MKTypography>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard image={mfa1} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h3" fontWeight="light" color="medium" textGradient>
              Experience unbeatable security with our built in Multi-Factor Authentication (MFA).
              Say goodbye to the risks of password breaches and hello to peace of mind
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Multiple & Flexible Authentication Factors
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              User can make a choice out of Hardware Based Security Token & variety of Authenticator
              Apps like Google Authenticator, Microsoft Authenticator or Authy.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={mfa2} title="SSO" description="" action={{}} />
          </Grid>
        </Grid>
        {/*  */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" my={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard image={mfa3} title="SSO" description="" action={{}} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MKTypography variant="h1" mb={6}>
              Passwordless Authentication
            </MKTypography>
            <MKTypography variant="body" color="black" style={{ fontSize: "22px" }}>
              User can select the authenticator of choice without entering password for login.
            </MKTypography>
          </Grid>
        </Grid>
        {/*  */}
      </Container>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          width="100%"
          borderRadius="xl"
          shadow="xl"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6, mt: 12 }}
        >
          <MKTypography variant="h1" mb={6}>
            Let&#39;s Start Today
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
