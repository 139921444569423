// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
// import TransparentBlogCard from "customBuilds/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
// import post1 from "assets/images/examples/testimonial-6-2.jpg";
// import mfa1 from "assets/images/intraas/mfa1.jpg";
// import mfa2 from "assets/images/intraas/mfa2.png";
// import mfa3 from "assets/images/intraas/mfa3.png";
// import sso4 from "assets/images/intraas/personcoding.jpg";
// import post2 from "assets/images/examples/testimonial-6-3.jpg";
// import post3 from "assets/images/examples/blog-9-4.jpg";
// import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox>
      <Container>
        <Grid container my={6}>
          <Grid item xs={12} md={12} sm={6} ml={3}>
            <MKTypography textAlign="center" variant="h2" mb={6}>
              Our Mission
            </MKTypography>
            <MKTypography
              variant="h4"
              textAlign="center"
              color="black"
              style={{ fontSize: "22px" }}
              mb={6}
            >
              Build niche technologies for smooth and secure functions of organisations, include
              Small & Medium Enterprises.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container my={6}>
          <Grid item xs={12} md={12} sm={6} ml={3}>
            <MKTypography textAlign="center" variant="h2" mb={6}>
              Our Vision
            </MKTypography>{" "}
            <MKTypography
              variant="h4"
              textAlign="center"
              color="black"
              style={{ fontSize: "22px" }}
              mb={6}
            >
              Enable world to focus on their businesses, while we ensure use of technology safer &
              easier.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          width="100%"
          borderRadius="xl"
          shadow="xl"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 6, mt: 12 }}
        >
          <MKTypography variant="h1" mb={6}>
            Let&#39;s Start Today
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
