// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "customBuilds/Navbars/DefaultNavbar";
import DefaultFooter from "customBuilds/Footers/DefaultFooter";
import FilledInfoCard from "customBuilds/Cards/InfoCards/FilledInfoCard";

// // Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/intraas/Testimonials";
// import Download from "pages/Presentation/sections/Download";
import UserCompanyData from "pages/intraas/UserCompanyData";
// import Posts from "pages/LandingPages/Author/sections/Posts";
// import IntraasInformation from "pages/intraas/IntraasInformation";
// import StaticFaqSection from "pages/intraas/FAQ/StaticFaqSection";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
// import bgImage from "assets/images/intraas/bg.png";
import bgImage from "assets/images/intraas/background5.png";
// import CaseStudy from "./components/CaseStudy";
import Contact from "pages/intraas/Contact/Contact";
import Newsletter from "./components/Newsletter";
// import bgImage from "assets/images/bg-presentation.jpg";

function HomePage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/intraas/contactUs",
          label: "Free Trial",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              INTRAAS{" "}
            </MKTypography>
            <MKTypography
              variant="h4"
              color="white"
              textAlign="center"
              px={{ xs: 4, lg: 0 }}
              mt={1}
            >
              Setup Your Organization <span style={{ color: "red" }}>INTRANET</span> On Cloud
            </MKTypography>
            {/* <GridContact
              container
              item
              xs={12}
              lg={12}
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
            >
              <MKTypography
                variant="h3"
                color="error"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                INTRANET
              </MKTypography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={12}
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 1, mx: "auto", px: 6 }}
            >
              <MKTypography
                variant="h3"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                On Cloud
              </MKTypography>
            </Grid> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography
          variant="h3"
          mb={1}
          mt={2}
          fontWeight="bold"
          alignItems="center"
          textAlign="center"
        >
          Reinventing Identity & Infrastructure Access for Your Business
        </MKTypography>
        <Container>
          <Grid container spacing={3} my={4}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="star"
                title="SSO"
                description="Simplify your login, streamline your workflow with Single Sign-On!"
                action={{
                  type: "internal",
                  route: "/intraas/sso",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="language"
                title="Identity based Infra-access"
                description="Identity authenticated Secure & Easy access to Machines of your IT infrastructure."
                action={{
                  type: "internal",
                  route: "/intraas/ibia",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="lock"
                title="MFA"
                description="Multiply the protection, multiply the peace of mind."
                action={{
                  type: "internal",
                  route: "/intraas/mfa",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <IntraasInformation /> */}
        <Newsletter />
        <Container>
          <MKTypography variant="h2" mb={4} fontWeight="bold" textAlign="center">
            Case Study&lsquo;s
          </MKTypography>
          <Grid container spacing={3} my={4}>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="description"
                title="Bring AI based edge device online"
                description="Future Standards"
                action={{
                  type: "external",
                  target: "_blank",
                  route: "https://drive.google.com/file/d/1enmQztTHdw9whTUIqwq5ooOOGHLONM1o/view",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="description"
                title="Usage in industrial automation "
                description="Basic Automation"
                action={{
                  type: "external",
                  target: "_blank",
                  route: "https://drive.google.com/file/d/18Bw21AaIHDFErRo4PqeFa7Sgfin9nVTn/view",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <CaseStudy /> */}
        <UserCompanyData />
        <Testimonials />
        <Contact />
        {/* <Divider sx={{ my: 6 }} /> */}
        {/* <Posts /> */}
        {/* <UserCompanyData /> */}
        {/* <BuiltByDevelopers /> */}
        {/* <Information /> */}
        {/* <Download /> */}
        {/* <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HomePage;
